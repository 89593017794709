<template>
  <div class="home">
    <img alt="Proposal Cafe Logo" height="123" src="../assets/proposal-cafe.png">
    <p style="margin:3em auto 1em auto;">Proposal Cafe is a tool for creating and tracking proposals for the SaaS Enterprise market space.</p>
    <div class="main-list">
      <ul>
        <li>Easy and fast</li>
        <li>Collaborative</li>
        <li>Free</li>
        <li>Early Access - Help shape this product</li>
        <li>To get involved, please drop us a note</li>
        <li><a href="mailto:info@proposal.cafe?subject=Proposal Cafe Information Request&body=Please contact me with information about Proposal Cafe">info@proposal.cafe</a> for more information</li>
      </ul>
    </div>  
    <div style="width:100%;font-size:.9em;position:absolute;bottom:5px;">
      <div style="text-align:center;">(c) Copyright 2022, Proposal Cafe, All Rights Reserved;  Version: {{$root.version}}</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
<style lang="scss">
  .main-list {
    width:640px;
    margin:2em auto 0 auto;
    padding:3em;
    background:#eee;
    border-radius:1em;
    ul {
      // list-style-type: none;
      text-align:left;
      li {
        font-size:1.25em;
        margin: .2em;
      }
    }
  }

</style>