<template>
  <div id="app">
    <div id="nav">
      <div v-if="updateExists" class="shadow" style="border:8px solid firebrick;cursor:pointer;position:absolute;top:10px;left:10px;z-index:9999;border-radius:2em;font-weight:700;background:darkSlateBlue;color:white;padding:2em;">
        <img src="@/assets/proposal-cafe.png" height="96"/>
        <h2 style="padding:0;">New Update Available</h2>
        <h4 style="padding:0;margin-left:auto;margin-right:auto;">{{$root.appTitle}} - Version: {{$root.versionOnly}}</h4>
        <b-button  style="margin-top:2em;background:green;color:white;float:right;"  @click="refreshApp()" >
            Apply This Update 
        </b-button>
      </div>
      <router-link to="/">Home</router-link> |
      <router-link to="/proposals">Proposals</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div>
</template>
<script>

  export default {
    name      : 'app',
    bodyClass : 'app',
    components: {},
    data() {
      return {
        refreshing  : false,
        registration: null,
        updateExists: false,
        title       : "Proposal Cafe"
      }
    },
    mounted() {
    },
    created() {
      document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    },
    methods   : {
      updateAvailable(message) {
        alert(message);
      },
      refreshApp() {
        console.log("app.vue->clicked refreshApp()");
        this.updateExists = false;
        if (!this.registration || !this.registration.waiting) {
          return;
        }
        this.registration.waiting.postMessage("skipWaiting");
      },
      showRefreshUI(e) {
        // this fires but doesn't show.
        console.log("app.vue->showRefreshUI()");
        this.registration = e.detail;
        this.updateExists = true;
      }
    }
  }

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
