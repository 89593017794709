import Vue from 'vue'
import {version} from '../package.json'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
  data: {     // note: no data return from root.
    title     : "Proposal Cafe in main.js",
    version   : version,
    appVersion: version
  } 
}).$mount('#app')
